// Constants
import { WatchlistProviders } from 'app/modules/detectionModels/components/scenarioWidgets/watchlist/enums';
import { UNLOADED_FLAGS_KEY } from 'app/shared/featureFlags/constants';

export enum FeatureFlagsActionTypes {
  SET_FEATURE_FLAGS = '@@featureFlags/SET_FEATURE_FLAGS',
}

// This enum should only contain active flags
// (makes archiving easier as tsc will complain when you pluck out a no-longer-needed flag)
export enum FeatureFlag {
  // Investigations
  EDIT_CUSTOM_DATA = 'edit-custom-data',
  ALERT_COMPONENT_BUTTONS = 'alert-component-buttons',
  SHOW_PAYER_PAYEE_TABS = 'show-payer-payee-tabs',
  REMOVE_DISPLAY_TAGS = 'remove-display-tags',
  BULK_ACTION_LIMIT = 'bulk-action-limit',
  ALERT_FROM_ALERT = 'alert-from-alert',
  COMPREHENSIVE_AUDIT_TRAILS = 'comprehensive-audit-trails',
  IMMUTABLE_NOTES = 'immutable-notes',
  WORKAROUND_HIDE_CURRENCY_CONFIG = 'workaround-hide-currency-code',
  CLASSIFIED_QUEUES = 'classified-queues',
  CODE_INTERPRETER = 'code-interpreter',
  DISPOSITION_ACTIONS = 'disposition-actions',
  WATCHLIST_ALERTS = 'watchlist-alerts',
  DEPRECATE_STATIC_WORKFLOW = 'deprecate-static-workflow',
  ALERT_TABLE_AMOUNT_COLUMN_SKELETON_LOADING = 'alert-table-amount-column-skeleton-loading',
  QUALITY_CYCLE_LIMIT = 'quality-cycle-limit',
  WATCHLIST_PROVIDER = 'watchlist-provider',
  FINCEN_CTR_FILINGS = 'fincen-ctr-filings',
  FRAUD_DASHBOARD = 'fraud-dashboard',
  ENTITY_NOTES = 'entity-notes',
  AI_RECOMMENDATIONS = 'ai-recommendations',
  BULK_RESOLVE_FROM_QUEUED_VIEWS = 'bulk-resolve-from-queued-views',

  // Delta Lake Transition Flags
  // Alert Pages
  CM_DELTA_LAKE_ALERT_LIST = 'CM_DELTA_LAKE_ALERT_LIST',
  CM_DELTA_LAKE_ALERT_DETAIL = 'CM_DELTA_LAKE_ALERT_DETAIL',
  CM_DELTA_LAKE_ALERT_TXN_ANALYSIS = 'CM_DELTA_LAKE_ALERT_TXN_ANALYSIS',
  CM_DELTA_LAKE_ALERT_TXN_LIST = 'CM_DELTA_LAKE_ALERT_TXN_LIST',
  CM_DELTA_LAKE_ALERT_ACTION_LIST = 'CM_DELTA_LAKE_ALERT_ACTION_LIST',
  CM_DELTA_LAKE_ALERT_HISTOGRAM = 'CM_DELTA_LAKE_ALERT_HISTOGRAM',

  // Case Pages
  CM_DELTA_LAKE_CASE_LIST = 'CM_DELTA_LAKE_CASE_LIST',
  CM_DELTA_LAKE_CASE_CGDO_SEARCH = 'CM_DELTA_LAKE_CASE_CGDO_SEARCH',
  CM_DELTA_LAKE_CASE_DETAIL = 'CM_DELTA_LAKE_CASE_DETAIL',
  CM_DELTA_LAKE_CASE_TXN_ANALYSIS = 'CM_DELTA_LAKE_CASE_TXN_ANALYSIS',
  CM_DELTA_LAKE_CASE_ENTITIES = 'CM_DELTA_LAKE_CASE_ENTITIES',
  CM_DELTA_LAKE_CASE_ACTION_EVENTS = 'CM_DELTA_LAKE_CASE_ACTION_EVENTS',

  // Filing Pages
  CM_DELTA_LAKE_FILING_SAR_LIST = 'CM_DELTA_LAKE_FILING_SAR_LIST',
  CM_DELTA_LAKE_FILING_CTR_LIST = 'CM_DELTA_LAKE_FILING_CTR_LIST',
  CM_DELTA_LAKE_FILING_GOAML_LIST = 'CM_DELTA_LAKE_FILING_GOAML_LIST',
  CM_DELTA_LAKE_FILING_SAR_DETAIL = 'CM_DELTA_LAKE_FILING_SAR_DETAIL',
  CM_DELTA_LAKE_FILING_CTR_DETAIL = 'CM_DELTA_LAKE_FILING_CTR_DETAIL',
  CM_DELTA_LAKE_GOAML_DETAIL = 'CM_DELTA_LAKE_GOAML_DETAIL',

  // Dashboard Pages
  CM_DELTA_LAKE_FRAUD_DASHBOARD = 'CM_DELTA_LAKE_FRAUD_DASHBOARD',

  // Data Explorer Pages
  CM_DELTA_LAKE_TXN_EVENT_LIST = 'CM_DELTA_LAKE_TXN_EVENT_LIST',
  CM_DELTA_LAKE_TXN_EVENT_DETAIL = 'CM_DELTA_LAKE_TXN_EVENT_DETAIL',
  CM_DELTA_LAKE_ACTION_EVENT_LIST = 'CM_DELTA_LAKE_ACTION_EVENT_LIST',
  CM_DELTA_LAKE_ACTION_EVENT_DETAIL = 'CM_DELTA_LAKE_ACTION_EVENT_DETAIL',
  CM_DELTA_LAKE_INSTRUMENT_LIST = 'CM_DELTA_LAKE_INSTRUMENT_LIST',
  CM_DELTA_LAKE_INSTRUMENT_DETAIL = 'CM_DELTA_LAKE_INSTRUMENT_DETAIL',
  CM_DELTA_LAKE_INSTRUMENT_RELATIONS = 'CM_DELTA_LAKE_INSTRUMENT_RELATIONS',
  CM_DELTA_LAKE_INSTRUMENT_TXN_EVENTS = 'CM_DELTA_LAKE_INSTRUMENT_TXN_EVENTS',
  CM_DELTA_LAKE_ENTITY_LIST = 'CM_DELTA_LAKE_ENTITY_LIST',
  CM_DELTA_LAKE_ENTITY_DETAIL = 'CM_DELTA_LAKE_ENTITY_DETAIL',
  CM_DELTA_LAKE_ENTITY_RELATIONS = 'CM_DELTA_LAKE_ENTITY_RELATIONS',
  CM_DELTA_LAKE_ENTITY_NETWORK_ANALYSIS = 'CM_DELTA_LAKE_ENTITY_NETWORK_ANALYSIS',
  CM_DELTA_LAKE_ENTITY_TXN_EVENTS = 'CM_DELTA_LAKE_ENTITY_TXN_EVENTS',
  CM_DELTA_LAKE_ENTITY_ACTION_EVENTS = 'CM_DELTA_LAKE_ENTITY_ACTION_EVENTS',
  CM_DELTA_LAKE_ENTITY_GEOLOCATION = 'CM_DELTA_LAKE_ENTITY_GEOLOCATION',

  // ML
  ALERT_SCORE = 'alert-score',
  ALERT_SCORE_AI_COPILOT = 'alert-score-ai-copilot',

  // DIM
  DARK_WEB_ALERTS = 'dark-web-alerts',
  CENSORSHIP_REVAMP = 'censorship-revamp',
  INSTRUMENT_ACTIONS = 'instrument-actions',

  // DM
  SIXTRAN_SNOWFLAKE_EXPECTED_LAG = 'sixtran-snowflake-expected-lag',
  FRAUD_TRANSACTION_RULES = 'fraud-transaction-rules',
  RULE_DELAY = 'rule-delay',
  ACH_RISK_SCORE_SCENARIO_ENABLED = 'ach-risk-score-scenario-enabled',
  RTM_VALIDATION_PICKER = 'rtm-validation-picker',
  ACH_RISK_SCORE_FIELD = 'ach-risk-score-field',
  RTM_DROPDOWN_BUILDER_FORM_REFACTOR = 'rtm-dropdown-builder-form-refactor',
  RULE_EVENT_SCHEMA_V2 = 'rule-event-schema-v2',
  RTM_SUBTYPE_LIMIT = 'rtm-subtype-limit',
  RTM_ALERT_PRI_QUEUE = 'rtm-alert-prio-queue',
  RTM_RELATIVE_DATE_SUPPORT = 'rtm-relative-date-support',

  // DE
  RISK_RATINGS_EXPORT = 'risk-ratings-export',

  // DevOps
  RULE_LIFECYCLE_BANNER = 'rule-lifecycle-banner',

  // Misc
  DARK_MODE = 'dark-mode',
  OUTDATED_INDICATOR = 'outdated-indicator',
  SESSION_REPLAY = 'sentry-session-replay',
  FEEDBACK_SURVEY_ID = 'feedback-survey-id',
  SIDE_BY_SIDE_INVESTIGATIONS = 'side-by-side-investigations',
  SIDE_BY_SIDE_INVESTIGATIONS_GA = 'side-by-side-investigations-ga',
}

export interface FeatureFlags {
  [FeatureFlag.DARK_MODE]?: boolean;
  [FeatureFlag.ALERT_SCORE]?: boolean;
  [FeatureFlag.ALERT_SCORE_AI_COPILOT]?: boolean;
  [FeatureFlag.EDIT_CUSTOM_DATA]?: boolean;
  [FeatureFlag.SHOW_PAYER_PAYEE_TABS]?: boolean;
  [FeatureFlag.REMOVE_DISPLAY_TAGS]?: boolean;
  [FeatureFlag.SIXTRAN_SNOWFLAKE_EXPECTED_LAG]?: number;
  [FeatureFlag.BULK_ACTION_LIMIT]?: number;
  [FeatureFlag.ALERT_FROM_ALERT]?: boolean;
  [FeatureFlag.RULE_EVENT_SCHEMA_V2]?: boolean;
  [FeatureFlag.IMMUTABLE_NOTES]?: boolean;
  [FeatureFlag.OUTDATED_INDICATOR]?: boolean;
  [FeatureFlag.FINCEN_CTR_FILINGS]?: boolean;
  [FeatureFlag.SESSION_REPLAY]?: boolean;
  [FeatureFlag.WORKAROUND_HIDE_CURRENCY_CONFIG]?: boolean;
  [FeatureFlag.CLASSIFIED_QUEUES]?: boolean;
  [FeatureFlag.FRAUD_TRANSACTION_RULES]?: boolean;
  [FeatureFlag.CODE_INTERPRETER]?: boolean;
  [FeatureFlag.WATCHLIST_ALERTS]?: boolean;
  [FeatureFlag.RTM_VALIDATION_PICKER]?: boolean;
  [FeatureFlag.ACH_RISK_SCORE_FIELD]?: boolean;
  [FeatureFlag.DEPRECATE_STATIC_WORKFLOW]?: boolean;
  [FeatureFlag.QUALITY_CYCLE_LIMIT]?: boolean;
  [FeatureFlag.FRAUD_DASHBOARD]?: boolean;
  [FeatureFlag.WATCHLIST_PROVIDER]?: WatchlistProviders;
  [FeatureFlag.RISK_RATINGS_EXPORT]?: boolean;
  [FeatureFlag.FEEDBACK_SURVEY_ID]?: number;
  [FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS]?: boolean;
  [FeatureFlag.SIDE_BY_SIDE_INVESTIGATIONS_GA]?: string;
  [FeatureFlag.CENSORSHIP_REVAMP]?: boolean;
  [FeatureFlag.AI_RECOMMENDATIONS]?: boolean;
  [FeatureFlag.INSTRUMENT_ACTIONS]?: boolean;

  // These are the Delta Lake Transition Flags
  [FeatureFlag.CM_DELTA_LAKE_ALERT_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ALERT_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ALERT_TXN_ANALYSIS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ALERT_TXN_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ALERT_ACTION_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ALERT_HISTOGRAM]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_CASE_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_CASE_CGDO_SEARCH]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_CASE_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_CASE_TXN_ANALYSIS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_CASE_ENTITIES]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_CASE_ACTION_EVENTS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_FILING_SAR_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_FILING_CTR_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_FILING_GOAML_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_FILING_SAR_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_FILING_CTR_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_GOAML_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_FRAUD_DASHBOARD]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_TXN_EVENT_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_TXN_EVENT_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ACTION_EVENT_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ACTION_EVENT_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_INSTRUMENT_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_INSTRUMENT_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_INSTRUMENT_RELATIONS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_INSTRUMENT_TXN_EVENTS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_LIST]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_DETAIL]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_RELATIONS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_NETWORK_ANALYSIS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_TXN_EVENTS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_ACTION_EVENTS]?: boolean;
  [FeatureFlag.CM_DELTA_LAKE_ENTITY_GEOLOCATION]?: boolean;

  // We use this flag to show the loading state
  [UNLOADED_FLAGS_KEY]?: boolean;
}

export interface FeatureFlagsState {
  flags: FeatureFlags;
}
