import {
  U21Card,
  U21ExternalLink,
  U21InfoItem,
} from 'app/shared/u21-ui/components';
import styled from 'styled-components';

interface AdverseMediaSummaryCardProps {
  notes: string;
  urls: string[];
  categories: string[];
}

export const AdverseMediaSummaryCard = ({
  notes,
  urls,
  categories,
}: AdverseMediaSummaryCardProps) => {
  return (
    <U21Card title="Adverse Media Summary" shaded>
      <StyledInfoItem label="Notes" width={90} valueProps={{ ellipsis: false }}>
        {notes.replaceAll('\\n', '\n')}
      </StyledInfoItem>

      <StyledInfoItem
        label="Categories"
        width={90}
        valueProps={{ ellipsis: false }}
      >
        {new Intl.ListFormat('en-US').format(categories)}
      </StyledInfoItem>

      <StyledInfoItem label="Source URLs" width={90}>
        {urls.length > 0 && (
          <URLList>
            {urls.map((url) => (
              <div key={url}>
                <U21ExternalLink variant="body2" url={url} />
              </div>
            ))}
          </URLList>
        )}
      </StyledInfoItem>
    </U21Card>
  );
};

const URLList = styled.div`
  overflow: hidden;
`;

const StyledInfoItem = styled(U21InfoItem)`
  margin-bottom: 12px;
  margin-top: 12px;
`;
